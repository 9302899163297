@use 'variables' as v;

@use 'node_modules/bootstrap/scss/bootstrap' as b with (
  $primary: v.$primary,
  $success: v.$success,
  $danger: v.$danger,

  $link-color: v.$danger,

  $font-family-sans-serif: sans-serif,

  $enable-rounded: false,
  $enable-smooth-scroll: false,
  $input-btn-focus-width: 0,
  $enable-negative-margins: true,
  $list-group-item-bg-scale: 80%,
  $list-group-item-color-scale: 40%,

  $paragraph-margin-bottom: 0,

  $input-height: 26px,

  $modal-dialog-margin-y-sm-up: 3rem,

  $navbar-nav-link-padding-x: 1rem,
  $navbar-brand-font-size: 2rem,
  $navbar-light-color: rgba(black, 0.75),
  $navbar-light-hover-color: rgba(black, 1),
  $navbar-light-active-color: rgba(black, 1),
  $navbar-dark-color: rgba(white, 0.75),
  $navbar-dark-hover-color: rgba(white, 1),
  $navbar-dark-active-color: white,

  $spacers: (
    0: 0,
    1: 1rem * 0.25,
    2: 1rem * 0.5,
    3: 1rem,
    4: 1rem * 1.5,
    5: 1rem * 3,
    6: 1rem * 4.5,
  )
);

@use 'node_modules/@ng-select/ng-select/scss/default.theme' as s with (
  $ng-select-border: b.$input-border-color,
  $ng-select-height: 38px,
  $ng-select-border-radius: 0,
  $ng-select-value-padding-left: 0.5rem,
  $ng-select-placeholder: b.$input-placeholder-color
);

@use 'node_modules/angular-notifier/styles' as n with (
  $notifier-error-background-color: b.$danger,
  $notifier-info-background-color: b.$info,
  $notifier-success-background-color: b.$success,
  $notifier-warning-background-color: b.$warning,
  $notifier-warning-font-color: b.$body-color,
  $notifier-warning-icon-color: b.$body-color,
  $notifier-info-font-color: b.$body-color,
  $notifier-info-icon-color: b.$body-color
);

html {
  height: 100%;
}

body {
  height: 100%;

  background: url(/assets/polyester.avif) fixed no-repeat;
  background-size: cover;

  --ck-z-default: 100;
  --ck-z-modal: calc(var(--ck-z-default) + 999);
  --ck-color-base-border: #{b.$input-border-color};
  --ck-color-base-error: #{b.$danger};
  --ck-color-focus-border: #{b.$input-focus-border-color};
  --ck-border-radius: 0;
  --ck-inner-shadow: 0;
  --ck-color-button-save: #{b.$success};
  --ck-color-button-cancel: #{b.$danger};
  --ck-focus-ring: 1px solid var(--ck-color-focus-border);
  --ck-drop-shadow: 0;
  --ck-focus-outer-shadow: 0;
}

nav {
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;

  .navbar-openable {
    display: none;

    &.navbar-open {
      display: flex;
    }
  }
}

.form-group {
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }

  label {
    margin-bottom: 0.5rem;

    &.required {
      &::after {
        content: ' *';
        color: b.$danger;
      }
    }
  }
}

ng-select.ng-select {
  .ng-select-container {
    &:hover {
      box-shadow: none;
    }
  }

  &.ng-invalid {
    &.ng-touched,
    &.ng-dirty {
      .ng-select-container {
        border-color: b.$danger !important;
      }

      &.ng-select-focused {
        .ng-select-container {
          box-shadow: 0 0 b.$input-btn-focus-blur b.$input-btn-focus-width
            rgba(b.$danger, b.$input-btn-focus-color-opacity) !important;
        }
      }
    }
  }

  &.ng-select-focused {
    .ng-select-container {
      border-color: b.$input-focus-border-color !important;
      box-shadow: b.$input-btn-focus-box-shadow !important;
    }
  }

  &.ng-select-single .ng-select-container .ng-value-container .ng-input {
    top: inherit;
  }

  .ng-arrow-wrapper {
    cursor: default;

    .ng-arrow {
      padding-top: 1px;
      border-top-color: b.$input-border-color;
    }

    &:hover {
      .ng-arrow {
        border-top-color: b.$input-border-color;
      }
    }
  }

  &.ng-select-opened {
    .ng-arrow-wrapper {
      .ng-arrow {
        border-bottom-color: b.$input-border-color;
      }
    }
  }

  .ng-clear-wrapper {
    color: b.$input-border-color;

    .ng-clear {
      padding-top: 4px;
    }

    &:hover .ng-clear {
      color: b.$danger;
    }
  }
}

.ck-editor__editable_inline {
  min-height: 200px;
}

@each $breakpoint in map-keys(b.$grid-breakpoints) {
  $infix: b.breakpoint-infix($breakpoint, b.$grid-breakpoints);

  @include b.media-breakpoint-up($breakpoint, b.$grid-breakpoints) {
    @if b.$grid-columns > 0 {
      @for $i from 1 through b.$grid-columns {
        .col#{$infix}-#{$i} {
          --cols: #{calc(b.$grid-columns / $i)};
        }
      }
    }
  }
}

.file-drop {
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border: 2px dashed b.$secondary;
  padding: 70px;
  cursor: pointer;

  * {
    pointer-events: none;
  }

  &.active {
    border-color: b.$primary;
  }
}

.cdk-drag {
  cursor: move;
}

.cdk-drag-placeholder {
  opacity: 0;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.alex-name {
  font-size: 39px;
  line-height: 36px;
}

.alex-title {
  font-size: 13px;
}

@include b.media-breakpoint-down(md) {
  .alex-name {
    font-size: 30px;
  }

  .alex-title {
    font-size: 10px;
  }
}

.navbar-toggler {
  border: none;

  &:focus,
  &:hover {
    box-shadow: none;
  }
}

.form-control {
  &.ng-invalid {
    &.ng-touched,
    &.ng-dirty {
      border-color: b.$danger;

      &:focus {
        box-shadow: 0 0 b.$input-btn-focus-blur b.$input-btn-focus-width
          rgba(b.$danger, b.$input-btn-focus-color-opacity) !important;
      }

      & + .invalid-feedback {
        display: block;
      }
    }
  }
}

.modal-dialog {
  @extend .modal-dialog-centered;

  .table {
    margin: 0.5rem 0;

    & > :not(caption) > * > * {
      padding: 0.5rem 1rem;
      border-bottom: 0;
    }
  }
}

.bg-material {
  background-color: #915d66;
  background-image: url(/assets/material.avif);
  background-size: cover;
}

.unlink,
.unlink:link,
.unlink:hover {
  color: inherit;
  text-decoration: inherit;
}

.polygon {
  clip-path: polygon(
    calc(var(--polygon-tl, 0%)) calc(var(--polygon-lt, 0%)),
    calc(100% - var(--polygon-tr, 0%)) calc(var(--polygon-rt, 0%)),
    calc(100% - var(--polygon-br, 0%)) calc(100% - var(--polygon-rb, 0%)),
    calc(var(--polygon-bl, 0%)) calc(100% - var(--polygon-lb, 0%))
  );

  &:hover {
    clip-path: none;
  }
}

.admin-bar {
  @extend .mx-n3;
  @extend .d-flex;
  @extend .align-items-center;

  a[role='button'] {
    @extend .mx-3;
    @extend .nav-link;

    span {
      @extend .d-none;
      @extend .d-md-inline;
      @extend .ms-2;
    }
  }
}
